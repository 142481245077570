/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import './scss/common.scss';

import LazyLoad from "vanilla-lazyload";
new LazyLoad({
    elements_selector: ".lazy",
    load_delay: 300, //adjust according to use case
    callback_error: (img) => {
        // Use the following line only if your images have the `srcset` attribute
        // img.setAttribute("srcset", "fallback_image@1x.jpg 1x, fallback_image@2x.jpg 2x");
        img.setAttribute("src", "/build/images/default.jpg");
    }
});
